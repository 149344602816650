import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import SEO from "../seo"

import { detectMob, useInput } from "@src/Helper"

import "./_get-started.scss"

function GetStarted() {
  const images = useStaticQuery(graphql`
    {
      jasmine: file(relativePath: { eq: "bots/jasmine-titled.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      sophie: file(relativePath: { eq: "bots/sophie-titled.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      theo: file(relativePath: { eq: "bots/theo-titled.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      sam: file(relativePath: { eq: "bots/sam-titled.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      grace: file(relativePath: { eq: "bots/grace-titled.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      zuhra: file(relativePath: { eq: "bots/zuhra-titled.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      startGame: file(
        relativePath: { eq: "backgrounds/guide-get-started.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      exm_admin: file(relativePath: { eq: "bots/executive-mafia-admin.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const id = useInput("get-started")

  useEffect(() => {
    if (detectMob()) {
      id.setValue("m-get-started")
    }
  }, [])

  return (
    <div id={id.value}>
      <SEO title="Get Started" />
      <div className="banner">
        <div className="banner-card">
          <div className="caption m-0">EXM Guide</div>
          <hr className="separator-mafia-primary" />
          <div className="title text-monday-blue">Bots and Rounds</div>
        </div>
      </div>

      <div className="container">
        <section className="section-top-150">
          <div className="row">
            <div className="col-12 col-md-5 order-2 order-md-1">
              <div className="guide-title-container">
                <div>
                  <div className="caption mb-0">Step 1</div>
                  <div className="title text-monday-blue">Meet the Bots</div>
                </div>
              </div>
              <p className="subcaption">
                Done activating your username?
                <br />
                As a <span className="text-monday-blue text-700">FREEMIUM</span>
                , you are granted to join the engagement rounds with{" "}
                <span className="text-monday-blue text-700">Theo</span> and
                obtain our latest updates from{" "}
                <span className="text-monday-blue text-700">Grandma Grace</span>
                .
              </p>
              <p className="subcaption">
                When you have{" "}
                <span className="text-monday-blue text-700">
                  subscribed to any of our plans
                </span>
                , you are now granted to join the engagement rounds with all of
                our bots, which are{" "}
                <span className="text-monday-blue text-700">Theo</span>,{" "}
                <span className="text-monday-blue text-700">Jasmine</span>, and{" "}
                <span className="text-monday-blue text-700">Sophie</span>.
              </p>
              <p className="subcaption">
                If you subscribe to a higher plan, you will be granted to do
                instagram researches with{" "}
                <span className="text-monday-blue text-700">
                  Analytic Tools
                </span>
                ! Go chat and click the start button on our bots now, which are{" "}
                <a href="https://t.me/theoexm_bot" target="_blank">
                  @theoexm_bot
                </a>
                ,{" "}
                <a href="https://t.me/jesmin_bot" target="_blank">
                  @jesmin_bot
                </a>
                ,{" "}
                <a href="https://t.me/sofie_bot" target="_blank">
                  @sofie_bot
                </a>{" "}
                and{" "}
                <a href="https://t.me/graceexmbot" target="_blank">
                  @graceexmbot
                </a>
                .
              </p>
            </div>

            <div className="col-12 col-md-7 order-1 order-md-2 mb-4 mb-md-0">
              <div className="d-flex flex-row flex-wrap justify-content-center">
                <div className="bot-image">
                  <Img
                    fluid={images.jasmine.childImageSharp.fluid}
                    alt="Jasmine bot"
                  />
                </div>
                <div className="bot-image">
                  <Img
                    fluid={images.theo.childImageSharp.fluid}
                    alt="Theo bot"
                  />
                </div>
                <div className="bot-image">
                  <Img
                    fluid={images.sophie.childImageSharp.fluid}
                    alt="Sophie bot"
                  />
                </div>
                <div className="bot-image">
                  <Img
                    fluid={images.grace.childImageSharp.fluid}
                    alt="Grace bot"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-100">
          <div className="row">
            <div className="col-12 col-md-7 mb-4 mb-md-0">
              <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                <div style={{ maxWidth: 450, width: "100%" }}>
                  <Img
                    fluid={images.startGame.childImageSharp.fluid}
                    alt="How to start using EXM services"
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-md-5">
              <div className="guide-title-container">
                <div>
                  <div className="caption mb-0">Step 2</div>
                  <div className="title text-monday-blue">Start the Game</div>
                </div>
              </div>
              <p className="subcaption">
                In 24 hours, our{" "}
                <span className="text-monday-blue text-700">
                  @ExecutiveMafiaAdmin
                </span>{" "}
                will reach out to you for the group invitation to EXM's
                marketplace:{" "}
                <span className="text-monday-blue text-700">the Ads room</span>{" "}
                on Telegram. When you have subscribed to any of our plans, our{" "}
                <span className="text-monday-blue text-700">
                  @ExecutiveMafiaAdmin
                </span>{" "}
                will reach out to you for the{" "}
                <span className="text-monday-blue text-700">
                  groups invitations
                </span>{" "}
                and{" "}
                <span className="text-monday-blue text-700">niche set-up</span>{" "}
                through Telegram.
              </p>
              <p className="subcaption">
                You may find what you need there! Make sure your Telegram
                username is the same as the username you registered on our
                website. Last but not least, while waiting for our{" "}
                <span className="text-monday-blue text-700">
                  @ExecutiveMafiaAdmin
                </span>{" "}
                to reach out to you, you can start joining the engagement rounds
                if you want to. Please note that each engagement bot's rule is
                different.
              </p>
            </div>
          </div>
        </section>

        <hr className="separator-mafia-primary" />

        <section className="section-100">
          <div className="text-center heading text-monday-blue mb-4">
            Have Questions?
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-7 d-flex align-items-center">
              <div style={{ width: 175, marginRight: "1.5em" }}>
                <Img
                  fluid={images.exm_admin.childImageSharp.fluid}
                  alt="Talk to Executive Mafia Admin via Telegram!"
                />
              </div>
              <div className="subcaption">
                <p>
                  If you still have any questions, you may reach out to our
                  support on Telegram{" "}
                  <a
                    className="text-monday-blue"
                    href="https://t.me/ExecutiveMafiaAdmin"
                    target="_blank"
                  >
                    @ExecutiveMafiaAdmin
                  </a>
                </p>

                <p className="mb-0">
                  or{" "}
                  <a href="mailto:support@executivemafia.com" target="_blank">
                    drop us a message via e-mail
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default GetStarted
